import { MenuBusInformationComponent } from 'app/menu/menu-bus-information/menu-bus-information.component';
import { MenuDashboardComponent } from 'app/menu/menu-dashboard/menu-dashboard.component';
import { MenuDeliveryComponent } from 'app/menu/menu-delivery/menu-delivery.component';
import { MenuDestinationSignComponent } from 'app/menu/menu-destination-sign/menu-destination-sign.component';
import { MenuDeviceComponent } from 'app/menu/menu-device/menu-device.component';
import { MenuDigitalSignageComponent } from 'app/menu/menu-digital-signage/menu-digital-signage.component';
import { MenuExternalContentManagerComponent } from 'app/menu/menu-external-content-manager/menu-external-content-manager.component';
import { MenuIndexWordComponent } from 'app/menu/menu-index-word/menu-index-word.component';
import { MenuLcdComponent } from 'app/menu/menu-lcd/menu-lcd.component';
import { MenuLedLayoutEditorComponent } from 'app/menu/menu-led-layout-editor/menu-led-layout-editor.component';
import { MenuMasterListComponent } from 'app/menu/menu-master-list/menu-master-list.component';
import { MenuMediaComponent } from 'app/menu/menu-media/menu-media.component';
import { MenuProjectComponent } from 'app/menu/menu-project/menu-project.component';
import { MenuRouteListComponent } from 'app/menu/menu-route-list/menu-route-list.component';
import { MenuScheduleMergeComponent } from 'app/menu/menu-schedule-merge/menu-schedule-merge.component';
import { MenuScheduleOperationManagerComponent } from 'app/menu/menu-schedule-operation-manager/menu-schedule-operation-manager.component';
import { MenuSignageDisplayEditorComponent } from 'app/menu/menu-signage-display-editor/menu-signage-display-editor.component';
import { MenuSimpleSignageEditorComponent } from 'app/menu/menu-simple-signage-editor/menu-simple-signage-editor.component';
import { MenuStationDisplayEditorComponent } from 'app/menu/menu-station-display-editor/menu-station-display-editor.component';
import { MenuTimetableEditorComponent } from 'app/menu/menu-timetable-editor/menu-timetable-editor.component';
import { MenuTimetableOperationManagerComponent } from 'app/menu/menu-timetable-operation-manager/menu-timetable-operation-manager.component';
import { MenuUserComponent } from 'app/menu/menu-user/menu-user.component';
import { EdsEditorComponent } from 'app/module/eds-editor/eds-editor.component';
import { MenuScheduleRegistrationComponent } from './../menu/menu-schedule-registration/menu-schedule-registration.component';

export class Constant {
  //#region base64
  static readonly PATH_ANGLE_DOUBLE_RIGHT: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjNCRTkyRkM0RDlFOTExRTlBRTM1RjM5Q0M2NTRCQTg0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjNCRTkyRkM1RDlFOTExRTlBRTM1RjM5Q0M2NTRCQTg0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0JFOTJGQzJEOUU5MTFFOUFFMzVGMzlDQzY1NEJBODQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0JFOTJGQzNEOUU5MTFFOUFFMzVGMzlDQzY1NEJBODQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4Nc/aKAAAAd0lEQVR42mK4devWdCBmZEADQLESIFZBF2cC4gwgnoZFkwwQH0DXxASlcWmSRtfEhCRJlCYmNElCmjRYGDABSNNBLOIgTcuZsEjMAOKVWMSfAnEoExbFWWpqav+xKHYAit9hIkUxsqeJUgzTMAWH4ifoikEAIMAA+lY/5WsBDz4AAAAASUVORK5CYII=';
  static readonly PATH_SELECT_AREA: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjEzMzNCNjgyNTc4NTExRTk5N0MxOEJDNDUxNTBGOTNGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjEzMzNCNjgzNTc4NTExRTk5N0MxOEJDNDUxNTBGOTNGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTMzM0I2ODA1Nzg1MTFFOTk3QzE4QkM0NTE1MEY5M0YiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTMzM0I2ODE1Nzg1MTFFOTk3QzE4QkM0NTE1MEY5M0YiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7EexO4AAAAuklEQVR42mL4////aiDOAWJeBnIBUHP2fwj4DMRTgVibHEN4oQYggwNAHArErKQYNPU/dvAMiBuAWIoYQ7T/4we/oWHnQMigA/+JA1eBOByXIaF4NP4C4oNAXA3EpkDMhMsQVmgYoANQoGuQEsANWAwAgTtALEOsIVLQQIR5QQNqAMkGrYZqOgjly5BsECgaoRqqkcTIMggUjWZoYqQZBFQQAcTMWMRlyAojehl0lpIiBGTQWSA2BwgwACwA0zBCqzt+AAAAAElFTkSuQmCC';
  static readonly PATH_CREATE_FIX_TEXT: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkY5OTQxMkIzRDlFQTExRTk5QUExREI0N0QwQzFEOUZGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkY5OTQxMkI0RDlFQTExRTk5QUExREI0N0QwQzFEOUZGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Rjk5NDEyQjFEOUVBMTFFOTlBQTFEQjQ3RDBDMUQ5RkYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjk5NDEyQjJEOUVBMTFFOTlBQTFEQjQ3RDBDMUQ5RkYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6fgYvZAAAAoklEQVR42mKo29X5H4gnMFAR/P//HwUzMdABjFpCHUuAEVb+nzxQTopPlID4HhALMSIBZAVo4kJQ9cakWjILqP89MUECVTcLqg8FsODRtweIV5MY/FjVs+BxWSepEQzUAwquTlAGHM0no5aMWkJDS4CZTBAfnyJLgIYZA/FuIPMMmtQZkDhIHp9+FiKLi7NAynU04uliCShOFIH4Ey0tAQgwAK6IcEyrfZqyAAAAAElFTkSuQmCC';
  static readonly PATH_CREATE_LINK_TEXT: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjIyRDk1N0M5RDlFQjExRTlBNzI5OEJFRkIzNDc2QTc3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjIyRDk1N0NBRDlFQjExRTlBNzI5OEJFRkIzNDc2QTc3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjJEOTU3QzdEOUVCMTFFOUE3Mjk4QkVGQjM0NzZBNzciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjJEOTU3QzhEOUVCMTFFOUE3Mjk4QkVGQjM0NzZBNzciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7glBG3AAABg0lEQVR42mKs29X5nwECLja5lRtA2QxAcQUgdZ8BCQDlGZH5SHphQBGo5gGS/AUgpQ9is0DFQALL0DR9AuKJDPjBRCx6kMFSIAY5xoARautSoEu6GWgAgOaXAqlohlFALgDFESilvQbG0VNkif///0sAKWkyzHzKyMj4AimOQGaIglLdeWjqKUDTwAXEP4H4OlDjXyQHGMNdych4FkmcGUhpAjEvEL9AMgeUGPKZ8LiMHYjfIFuCN2gg6t5A9WEAFjx630MxKQCnepBFH4H4OxYXviA5whkZf6IFGwPU7I+MpBqGK44IASZ6JW+6WcQCTOcCoGQMzEffaVTWcYJSIhM0pbTT0DMgs98Pvziiq0WCQFxJZB5ixsfHAUBmCzISaQEXtCRnRyvLfkIxqMT+NsJqPmCG+gDE7TQ0vx1kB6j05gfiMCCHA1g6FCIpEANSs9DadQFohmxAMzcNqOYVknw/kPID2QGy6CEQg1KPOpomNlB7jICDDbDoQQbqULMfAgQYABYJfZLcvOGFAAAAAElFTkSuQmCC';
  static readonly PATH_CREATE_FIX_PICTURE: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjNFRDg2NjRCRDlFQjExRTlCM0I4QzA5REFDMjY2RDM0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjNFRDg2NjRDRDlFQjExRTlCM0I4QzA5REFDMjY2RDM0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0VEODY2NDlEOUVCMTFFOUIzQjhDMDlEQUMyNjZEMzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0VEODY2NEFEOUVCMTFFOUIzQjhDMDlEQUMyNjZEMzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7GxQrKAAABg0lEQVR42tRVwUoDMRBtVLSrgoKCB4WKRcGDUhC87m/4B/bop3jtH/gN3notCILgQaSKoOCpaC3VYk3fyIykodlNt82CDx6Tzib7ZjKzHVW7uNKFHDDH9hqsG/4FsDrCnwUxWBGh+unJ0Zk8QZarLDTkzwK865yEZgo5ITchxc3wBX6afnAZ7PGzSVCkmkuNmmAjUDLH4L4IXU5a9JRm+BP6hda6yKlmwbtS6iftOxJQW29mFOoi0DuIffsICW7YLoLz4BslnCByAEbgnktspBA29nBgCcuydA58Ty4V7JWlUyzpO4oc6zSI2KyvUIuvrAu+jFkvElv3qRFdXx/mnq8mAuFSmn+vwGxRAPC1jJqaNVNeQsb9U612wQ+sH7krJdod+F5hnyUIq2Z+QoYI3TdlcWhHCmxQd2Jv09XaiUI4SDOpBPaZSaC9ZZx5oI4dSwgH6M/0dtoT1s5mO9Qot7H2bwefnVGbWnVK7247hdAAHZhOyIxiHlAhEJtCFWYwDAQYAPrmheE5FSFJAAAAAElFTkSuQmCC';
  static readonly PATH_CREATE_LINK_PICTURE: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OTNGMkNGRDlFQjExRTk4NDdDODcxNjhFQzBFNjE1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjU5OTNGMkQwRDlFQjExRTk4NDdDODcxNjhFQzBFNjE1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTk5M0YyQ0REOUVCMTFFOTg0N0M4NzE2OEVDMEU2MTUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTk5M0YyQ0VEOUVCMTFFOTg0N0M4NzE2OEVDMEU2MTUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4b328dAAABzElEQVR42rxWzUoDMRBOttsu/lXQiyDSFqlFPLjQV/DiK9hnEHvx2oNXLy0+Q30FL76CoBfBg1RBwYsV2pOIrt/oRNKY7h/bHfiYZLOZb5KZSSIvzy4C8Ss3e4f7PrcFvlehBkITjEu9r81VUsM/D9r4NdQutV3+Rh/OjUkjoCfCpWeZo0sfIGd8yax9eHIqZiCwfwzVmtiKIAgWoJZS2nyVUn5MGzSJ1qDWUxIRyR3I3m2DrpVdyqskDHCwCVUEGmhbyVzOrhFiNLQYoG30gCEmf8Xg/EcG+ytQZYdTuDOFZAuoABsJFqjIitwn2wMnZEJJa3sJ40Ukq5ExYnkDFpnkKcyqGVOOmYhFxDF5nEVt/aS3zZsUdppcKn/iiJzESelxCdjmkyQ7IhicA6Qi4bSfB+pxyRw66oFuCAkZagCbaHtMotK9EEVGtonDiVgJGaizwWVgx1JThTgrc0NIPD4VPhlRUsGc+0SHKtcRTbjNKuuIqGbejPCsmmFmnwDdLO8jU56xKy+5F6wZozF5kpHt8UTM83qcuPzuoqIVOhnfjB3jXdc2i9EMvH5TM8kBcait8/mhoq+qDBwZhtpG3xwnYv1J0FIPyG8BBgC5a8keo8nB4wAAAABJRU5ErkJggg==';
  static readonly PATH_ZOOM_PAN: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjY5MjRDQTY3RDlFQjExRTlBQjFEOEU5NkIwMDVCODM1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjY5MjRDQTY4RDlFQjExRTlBQjFEOEU5NkIwMDVCODM1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NjkyNENBNjVEOUVCMTFFOUFCMUQ4RTk2QjAwNUI4MzUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NjkyNENBNjZEOUVCMTFFOUFCMUQ4RTk2QjAwNUI4MzUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6W7Tn6AAABF0lEQVR42uzUz0oCURTH8YsJ7QpcRYSzc+vKl/AFotaBDxGCK4XArT6Bm/DPuq0uJQhaCpWrlhGJlUTX76EzoGB3zujWAx9mMYffnXvnzDi3Ut77Ejp49X81wxAVZJ21aD7ADd5QQxFHOMMl7vGAyBooYY/IBxZsYoJcUlhJnyxvWLiLdlKTnFnNuJMIn3IcoSZ5AcUU5z1G+b/7GZzgydnrGaehwDmOUwRK70doCyMZDeN2D/GOQqiponOWMQTWMU1qyurQNhP6LvCLH1Qt4zDROYs2bLOhYXEtLKE5tHTOZDRucadn9qJP5lOFarB8w2Vc4Tx+AVyv8b1VaGCxfeha6Bf6bpdaCZWwgfw33a6lob04bCnAADrK1ds+t3liAAAAAElFTkSuQmCC';
  static readonly PATH_ALIGNMENT: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkBAUSFyJMHheqAAAAiUlEQVQ4y2NgYGBg+C/2//F/XgaiABMDAwMDgxCDDAMfKRpIALTXwILM+S/LYIKh4hXjUQyx/xr///+XZmD4H/cfE+wdWp5mWM2wF0PFDzwaGL8zPKWtk/5zMgjhUfuS8Q9MIb54QAANspxE73jYzxCER+0TDA2MjxkeD7wfyNTwjuExwyfiNAAAA0JRHw9d1jEAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDQtMDVUMTg6MjM6MzQrMDA6MDCROqUvAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTA0LTA1VDE4OjIzOjM0KzAwOjAw4GcdkwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=';
  static readonly PATH_ALIGN_LEFT: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAYAAAC0EpUuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg2MjBCQTkzRDlFOTExRTlBRkFDRDY4RDJCRjQ2RDY2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg2MjBCQTk0RDlFOTExRTlBRkFDRDY4RDJCRjQ2RDY2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODYyMEJBOTFEOUU5MTFFOUFGQUNENjhEMkJGNDZENjYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODYyMEJBOTJEOUU5MTFFOUFGQUNENjhEMkJGNDZENjYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4pnW2OAAAAm0lEQVR42mJISUn5wEBF8P//fwYWGAdoODOQ4iWg58+cOXO+EDKYBYmtC8TngPg3DrVMQHweiM1IMRQEPgExPx71bMQEARMDDcDQNZSVGoYiR9RHID5KwODTRJlKi8RPkzBFzlGiQCqaCmZOQA5TaSDuoIIjJ6DnqB8EchQh8JNm6RTdpXxA/IsC89jRDb0MxEJUcSot0ilAgAEAxN0iYtWzWJAAAAAASUVORK5CYII=';
  static readonly PATH_ALIGN_CENTER: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjk5NkZBMTlDRDlFOTExRTlBNDdFQTY2OUVGMDczRDNGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjk5NkZBMTlERDlFOTExRTlBNDdFQTY2OUVGMDczRDNGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTk2RkExOUFEOUU5MTFFOUE0N0VBNjY5RUYwNzNEM0YiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTk2RkExOUJEOUU5MTFFOUE0N0VBNjY5RUYwNzNEM0YiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6XUrjzAAAAqUlEQVR42mJgIABSUlL+E1Lz//9/BkYsGvmBFLL4eyAWRNY3Z86cj+gGsWCx4CkQs4HkkcReQmmQBb+AmAddEzaDmIGYFU2MDYn9F5v3mBioBIaxQdgCeysQCyPxHYD4ABL/LVk2EZsgqec1oI0FRLiKkJoJoDDqAeI/BBR2EAhnsEEgQ9gJGIRP/ifVo58dmhFxATYC8uwwgwQJWAYqRsQpdjKx6QggwACjICvLUfXe3gAAAABJRU5ErkJggg==';
  static readonly PATH_ALIGN_RIGHT: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAYAAAC0EpUuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE5ODNCQjI4RDlFOTExRTk4RUQyOTA5NjAwNEMxQTNBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE5ODNCQjI5RDlFOTExRTk4RUQyOTA5NjAwNEMxQTNBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTk4M0JCMjZEOUU5MTFFOThFRDI5MDk2MDA0QzFBM0EiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTk4M0JCMjdEOUU5MTFFOThFRDI5MDk2MDA0QzFBM0EiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz44X9O+AAAAf0lEQVR42mJgoCJISUn58P//fwYWIhSKAykOAsqez5kz5xeMw0KEA+4CMTcBNRFAvBLGYSLCUEJq/pOqgSwwsgxlRBcgJvYvA7EaHvm/oCTFQCsAS/w0CVMWoOnrqWBOJTBH3UAO0wAqGLoCiG9QM/b/D+10qkAFg2mfTgECDAAfVSMVl2+BfQAAAABJRU5ErkJggg==';
  static readonly PATH_ALIGN_TOP: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkU2MEJBNDhERDlFOTExRTk4NUFFQUM1NDlERTk4QzUwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkU2MEJBNDhFRDlFOTExRTk4NUFFQUM1NDlERTk4QzUwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTYwQkE0OEJEOUU5MTFFOTg1QUVBQzU0OURFOThDNTAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTYwQkE0OENEOUU5MTFFOTg1QUVBQzU0OURFOThDNTAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5HFi5MAAAAiUlEQVR42mJMSUn5zzAYASOMAXShLpBiRpL7MmfOnDtQOTYgpYXDjL9AdZdBDBYkwRNQw/9DDQUpMIXKSQHxWSD+gcUxIPXc6IYxATEHEp8VTSPIIC4sLvuBbADVwKhhtDWMmZACFiIN+gFNd6xY5N6QZBgwhb9ASsCjsTniDPuLJveTVMMAAgwA3UQbxMapZV4AAAAASUVORK5CYII=';
  static readonly PATH_ALIGN_MIDDLE: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAARCAYAAAA/mJfHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkQ0RURGNEVFRDlFOTExRTk4NkQ0RUFGRDI3MzYyODMyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkQ0RURGNEVGRDlFOTExRTk4NkQ0RUFGRDI3MzYyODMyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RDRFREY0RUNEOUU5MTFFOTg2RDRFQUZEMjczNjI4MzIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RDRFREY0RUREOUU5MTFFOTg2RDRFQUZEMjczNjI4MzIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz49Pm8YAAAAk0lEQVR42mJkIBGkpKScBFJmSEJf58yZwwNiMDGQDtjR+MwwBjmG4QRUNYwFKSyeAykBLGr+AbEmMFweEW0Y1CAOLGq+E+sDYhT9H5AwYwSG1X+qGYYUAd9xhNk3INYGRsADqLoLQEofSf4HUI5zQNIZI8npDAg+4Ehn/6FpjXjDgP6WHFTZacAN+4nG/wtjAAQYAIj/IU4OkAkyAAAAAElFTkSuQmCC';
  static readonly PATH_ALIGN_BOTTOM: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkM0RDkwNDg0RDlFOTExRTlBOTlDRDY5OTY3NjE3QUU3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkM0RDkwNDg1RDlFOTExRTlBOTlDRDY5OTY3NjE3QUU3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzREOTA0ODJEOUU5MTFFOUE5OUNENjk5Njc2MTdBRTciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzREOTA0ODNEOUU5MTFFOUE5OUNENjk5Njc2MTdBRTciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7+s17rAAAAh0lEQVR42mJkIBGkpKScBFJmSEJf58yZwwNiMDGQDtjR+MwwBjmG4QSjhg2wYSxI6WcPkBLBouY3EPsC09ILog0DAmsg5sCi5icOcbK8+Xc0NodaOgOCf0D8HYj/o6nhwJLufkJjmRFZPbJhFshlExp4hsSOBGIecpIOSYARmI3+U8swgAADABhUGRNCW0A0AAAAAElFTkSuQmCC';
  static readonly PATH_ALIGN_DISTRIBUTE_HORIZONTAL: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAWCAYAAAArdgcFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkY5NzkyQjExRDlFOTExRTlBQjc1OTI1OERBRTYyQ0VDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkY5NzkyQjEyRDlFOTExRTlBQjc1OTI1OERBRTYyQ0VDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Rjk3OTJCMEZEOUU5MTFFOUFCNzU5MjU4REFFNjJDRUMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjk3OTJCMTBEOUU5MTFFOUFCNzU5MjU4REFFNjJDRUMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5C+6LaAAAAvElEQVR42mJgQAIpKSkJQLwATew/AxaALg7SB9KPLMbEQEMwajhWwILG3wfEl9DEAnHoRRefBMRvkAUYgTEcgKbo0Zw5c86R6kqgOUZASg7d5euB+BsSfzsQB5ARCnVA7AnEf6B8LliwcFEhHkD62KB4mKUWbBH1HEixYpH6DYx4SYoMBwKJ0ew/jFILCeAnEP+FYhBgo6bhmUBcSo7Lf2ERY0PmANP8G/RSkRjDFakZ5j/QXPSAXMMBAgwA6tMrb8NV7TgAAAAASUVORK5CYII=';
  static readonly PATH_ALIGN_DISTRIBUTE_VERTICAL: string =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAXCAYAAAD+4+QTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFFQTI0NjdDRDlFQTExRTlCRDhBQzg3MDk4NDZDOUQ1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFFQTI0NjdERDlFQTExRTlCRDhBQzg3MDk4NDZDOUQ1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUVBMjQ2N0FEOUVBMTFFOUJEOEFDODcwOTg0NkM5RDUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUVBMjQ2N0JEOUVBMTFFOUJEOEFDODcwOTg0NkM5RDUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6lSXLNAAAAsUlEQVR42mJkIAKkpKT8ZyANfJkzZw4vjMPEQBvAgsyhlSWoNgKDIgGL+DWgd09R01sBWMSZgRjZkh1AzEGCuW8ZRgG5gBFHHpgIjPgCCvNNINCMDbCIF8Si4CeFjv+OkrqAtn2gQQj9p3tmpFuOF8AWJ8Bg/E5JgkL3yXssuJ1Cx3OOZlyKcvwGLOKbgRE/Fyi3ncTSFxv4Ccrx2Cy5BqU9qJKEgS5eMCwyI10sAQgwAFjpMyKQDTikAAAAAElFTkSuQmCC';
  //#endregion

  static readonly BOLD_STYLE: string = 'bold';
  static readonly ITALIC_STYLE: string = 'italic';
  static readonly FONT_COLOR_DEFAULT = '#ffffffff';
  static readonly BACKGROUND_COLOR_DEFAULT = '#00000000';
  static readonly OUTLINE_COLOR_DEFAULT = '#010101ff';
  static readonly MAX_INDEX_WORD: number = 10;
  static readonly MAX_INDEX_WORD_GROUP: number = 10;
  static readonly CANCEL_DIALOG_CHANGE_LABEL: String = 'cancel_dialog_change_label';
  static PLAY_SEGMENT: number = 120;
  static readonly SEGMENT_HEIGHT: number = 66;
  static readonly ACTION_DEFAULT = '';
  static readonly ACTION_EDIT_MASTERLIST = 'edit-masterlist';
  static readonly ACTION_CLOSE = 'close';
  static readonly ACTION_ADD = 'add';
  static readonly EMPTY = '';
  static readonly BACKEND_URL = window.location.origin + '/';
  static readonly COMMON_SERVICE_URL = 'services/common/';
  static readonly BID_SERVICE_URL = 'services/bid/';
  static readonly DSC_SERVICE_URL = 'services/dsc/';
  static readonly PUBLISH_SERVICE_URL = 'services/publish/';
  static readonly UAA_SERVICE_URL = 'services/uaa/';
  static readonly EDS_SERVICE_URL = 'services/eds/';
  static readonly BUS_INFORMATION_NAME = 'bus-information';
  static readonly DIGITAL_SIGNAGE_NAME = 'digital-signage';
  static readonly LCD_LAYOUT_NAME = 'lcd-layout';
  static readonly ROUTE_LIST_NAME = 'route-list';
  static readonly IN_HOUR = 'in-hour';
  static readonly IN_MINUTE = 'in-minute';
  static readonly IN_SECOND = 'in-second';
  static readonly OUT_HOUR = 'out-hour';
  static readonly OUT_MINUTE = 'out-minute';
  static readonly OUT_SECOND = 'out-second';
  static readonly HIDDEN = 'HIDDEN';
  static readonly SHOW = 'SHOW';
  static readonly MEDIA_FOLDER = 'media';
  static readonly RECORDS_PER_PAGE = 30;
  static readonly FONT_FOLDER = 'font';
  static readonly CELL_HEIGHT: number = 66;
  static readonly HOUR = 'hr';
  static readonly MINUTE = 'min';
  static readonly SECOND = 'sec';
  static readonly REGEX_TIME = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';
  static readonly FORMAT = '[!@#$%^&*(),.?":{}|<>/]';
  static readonly MIN_TEMPLATE_SIZE = 1;
  static readonly MAX_TEMPLATE_SIZE = 3840;
  static readonly MIN_SCREEN_SIZE = 1;
  static readonly MAX_SCREEN_SIZE = 3840;
  static readonly MAX_LENGTH_TEMPLATE_GROUP_NAME = 16;
  static readonly MAX_LENGTH_DEVICE_ID = 16;
  static readonly MAX_LENGTH_FULL_NAME = 64;
  static readonly MAX_LENGTH_TEMPLATE_NAME_LCD = 16;
  static readonly MAX_LENGTH_TEMPLATE_NAME_LED = 72;
  static readonly MIN_TIME_AUTO_TRANSITION = 1;
  static readonly MAX_TIME_AUTO_TRANSITION = 600;
  static readonly FIELD_COMPONENT_1 = 'fieldComponent1';
  static readonly FIELD_COMPONENT_2 = 'fieldComponent2';
  static readonly FIELD_COMPONENT_3 = 'fieldComponent3';
  static readonly LIST_ABORT_TYPE_NUMBER = ['+', '-', 'e', 'E', '.'];
  static readonly CUSTOM_TAG_01_KEY = 'customTag1';
  static readonly CUSTOM_TAG_02_KEY = 'customTag2';
  static readonly CUSTOM_TAG_03_KEY = 'customTag3';
  static readonly LIST_CUSTOM_TAG_ELEMENT_1 = 'listCustomTagElement1';
  static readonly LIST_CUSTOM_TAG_ELEMENT_2 = 'listCustomTagElement2';
  static readonly LIST_CUSTOM_TAG_ELEMENT_3 = 'listCustomTagElement3';
  static readonly LABEL_1 = 'label1';
  static readonly LABEL_2 = 'label2';
  static readonly LABEL_3 = 'label3';
  static readonly IS_SELECTED = 'isSelected';
  static readonly IS_CHANGE_TIME_ZONE = 'isChangeTimeZone';
  static readonly IS_CHANGE_FONTS = 'isChangeFonts';
  static readonly TTC_TYPE = '.ttc';
  static readonly TTF_TYPE = '.ttf';
  static readonly MIN_TIME_SECONDS = 0;
  static readonly MAX_TIME_SECONDS = 60;
  static readonly CONTENT_DAY_ELEMENT = 0;
  static readonly LIST_DAY_ELEMENT = 1;
  static readonly IS_UNLIMITED_ELEMENT = 2;
  static readonly SCREEN_ELEMENT = 3;

  static readonly HEIGHT_SOFTWARE = 490;
  static readonly WIDTH_POPUP = 165;
  static readonly ERROR_CODE_MAX_LENGTH_NAME = -1;
  static readonly MAX_LENGTH_MEDIA_NAME = 256;

  static readonly TIMETABLE_DEVICE_COMPLETED_IDS = 'timetableDeviceCompletedIds';

  static readonly SIMPLE_DEVICE_COMPLETED_IDS = 'simpleDeviceCompletedIds';
  static readonly PREFIX_AREA = 'area_';

  static readonly FORMAT_TIMETABLE_NAME = '[:*?"<>|/]';
  static readonly FORMAT_INDEX_WORD_GROUP_NAME = '[:*?"<>|/]';
  static readonly NUMBER_OF_FILES_ALLOWED_TO_EXPORT = 10;
  /**
   * constant width menu of screens
   */
  static readonly WIDTH_CHECK_EMERGENCY = 1007;
  static readonly WIDTH_CHECK_SETTING = 915;
  static readonly WIDTH_CHECK_JOB = 785;
  static readonly WIDTH_CHECK_DELIVERY = 687;
  static readonly WIDTH_CHECK_PUBLISH_SIGNAGE = 900;
  static readonly WIDTH_CHECK_SETTING_SIGNAGE = 840;
  static readonly WIDTH_CHECK_DISPLAY_SIGNAGE = 760;
  static readonly WIDTH_CHECK_PUBLISH_TIMETABLE = 950;
  static readonly WIDTH_CHECK_PUBLISH_ON_BUS = 730;
  static readonly WIDTH_CHECK_UPDATE_DATA = 1110;
  static readonly WIDTH_CHECK_MESSAGE = 840;
  static readonly WIDTH_CHECK_EMERGENCY_SCHEDULE = 965;
  static readonly WIDTH_CHECK_NETWORK = 1920;

  static readonly WIDTH_CHECK_MERGE_SCHEDULE = 840;
  static readonly WIDTH_CHECK_UPDATE_DATA_SCHEDULE_MERGE = 1200;
  static readonly WIDTH_CHECK_CREATE_DAILY_SCHEDULE = 1000;

  static readonly WIDTH_CHECK_REGISTER = 725;

  static readonly DEFAULT_TEMPLATE_WIDTH = 1920;
  static readonly DEFAULT_TEMPLATE_HEIGHT = 1080;

  static readonly IS_NOT_DELIVERY = 0;
  static readonly DELIVERED = 1;

  static readonly MAX_CUSTOM_TAG = 3;
  static readonly MAX_CUSTOM_TAG_ELEMENT = 10;
  static readonly INDEX_OF_COLUMN_CUSTOM_TAG = 3;

  static readonly PROJECT_ID = 'projectId';
  static readonly BID_SCREEN = 'BID';

  static readonly DISPLAY_1 = 'display1';
  static readonly DISPLAY_2 = 'display2';

  static readonly CANVAS_DISPLAY_1_ID = 'canvasDisplay1';
  static readonly CANVAS_DISPLAY_2_ID = 'canvasDisplay2';
  static readonly TIMETABLE_CANVAS_DISPLAY_1_ID = 'timetableEditorCanvasDisplay1';
  static readonly TIMETABLE_OPERATION_CANVAS_DISPLAY_1_ID = 'timetableOperationCanvasDisplay1';
  static readonly SAVE_SUCCESS = 'Save successfully.';
  static readonly DEPENDS_ON_FILE = 'Depends on file';
  static readonly FIELD_DURATION = 'duration';
  static readonly FIELD_VALID_FROM = 'validFrom';
  static readonly FIELD_VALID_TO = 'validTo';
  static readonly SUFFIX_TIME = 'T00:00:00';
  static readonly TMP_TIME_NULL = '00:00';
  static readonly FORMAT_DATE = 'YYYY-MM-DD';

  static readonly IS_MEDIA_IN_STATION_CONTENT_FOLDER = 'isMediaInStationContentFolder';
  static readonly IS_MEDIA_IN_FREE_AREA_FOLDER = 'isMediaInFreeAreaFolder';
  static readonly MEDIA_VALUE = 'media';

  static readonly IS_MEDIA_IN_LCD_LAYOUT_EDITOR = 'isMediaInLCDLayoutEditorFolder';
  static readonly FOLDER_LCD_LAYOUT_EDITOR = 'LCD Layout Editor';
  static readonly FOLDER_INDEX_WORD_EDITOR = 'Index Word Editor';

  static readonly MEDIA_TYPE_IMG = 0;
  static readonly MEDIA_TYPE_MP3 = 1;
  static readonly PROJECT_MANAGER_COMPONENT_URL = 'project-manager';
  static readonly BUS_INFO_DISPLAY_COMPONENT_URL = 'bus-info-display';
  static readonly ROUTE_LIST_EDITOR_COMPONENT_URL = 'route-list-editor';
  static readonly MEDIA_MANAGER_COMPONENT_URL = 'media-manager';
  static readonly LCD_LAYOUT_EDITOR_COMPONENT_URL = 'lcd-layout-editor';
  static readonly STATION_DISPLAY_EDITOR_COMPONENT_URL = 'station-display-editor';
  static readonly DIGITAL_SIGNAGE_CHANNEL_EDITOR_COMPONENT_URL = 'digital-signage-channel-editor';
  static readonly MASTER_LIST_EDITOR_COMPONENT_URL = 'master-list-editor';
  static readonly INDEX_WORD_EDITOR_COMPONENT_URL = 'index-word-editor';
  static readonly SIGNAGE_DISPLAY_EDITOR_COMPONENT_URL = 'signage-display-editor';
  static readonly DASH_BOARD_COMPONENT_URL = 'dash-board';
  static readonly TIMETABLE_COMPONENT_URL = 'timetable-editor';
  static readonly SCHEDULE_REGISTRATION_COMPONENT_URL = 'schedule-registration';
  static readonly TIMETABLE_OPERATION_URL = 'timetable-operation';
  static readonly SIMPLE_SIGNAGE_EDITOR_URL = 'simple-signage-editor';
  static readonly LED_LAYOUT_EDITOR_COMPONENT_URL = 'led-layout-editor';
  static readonly SCHEDULE_MERGE_COMPONENT_URL = 'schedule-merge';
  static readonly DAYS_LEFT_TO_DEADLINE = 3;
  static readonly USER_ROOT = 'root';
  static readonly SKIP_WAITING_URLS = [
    'auth/inactive',
    'api/delivery-jobs/update-progress-for-jobs',
    'api/delivery-jobs/update-progress-for-detailed-job',
    'api/devices/update-status-for-devices',
    'api/picture-areas/get-news-picture-url-presigned',
    'api/delivery-status-timetable/update-status-for-devices',
    'api/timetable-delivered-content-days/timetable-editor/save',
    'api/simple-delivery-status/update-status-for-devices',
    'simple-signage-editor/update-status-delivery-for-content-days-by-device-ids',
    '/timetable_realtime_status',
    'api/simple-delivery-status/update-detail-status-for-device',
    'api/delivery-status-destination/update-status-for-devices',
    '/job_process_details',
    '/device_list_for_job',
    'delivery-status-timetable/update-status-for-group-devices',
    'get-detail-status-for-single-device',
    'save-detail-status-for-devices',
    'api/publish/timetable-display',
    '/delivery-for-timetable',
    '/devices/update-time-date-line-for-device',
    '/device-publish-infos/call-api-for-group-devices-timetable',
    '/device-publish-infos/call-api-for-single-devices-timetable',
    'api/publish/simple-signage',
    '/delivery-for-simple-signage',
    '/group_delivery',
    '/single_delivery',
    '/download-data-publish',
    'auth/write-error-log',
    'api/schedule-merge/save-schedule-edit',
    'api/schedule-merge/save-after-update',
    'api/schedule-merge/create-daily-schedule'
  ];

  static readonly SKIP_RETRY_URLS = [
    '/medialog',
    '/devicelog',
    '/update-status-for-devices',
    '/eds-route-list',
    '/single_delivery',
    '/group_delivery',
    '/job_process_details',
    '/device_list_for_job',
    '/get-detail-status-for-single-device',
    '/get-detail-status-for-single-device',
    '/download-data-publish',
    '/get-delivery-status-for-devices',
    'auth/write-error-log',
    '/get-device-information'
  ];

  static readonly TIME_ZONE_DEFAULT = '(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London';
  static readonly TENANT_HEADER = 'X-TenantID';
  static readonly USER_ID = 'userID';
  static readonly ROOT = 'root';
  static readonly FOCUS_INPUT_TIME = 'focus-input-time';
  static readonly UPDATE_VIEW_BY_PRIVILEGES = 'updateViewByPrivileges!';

  static readonly MIN_NO_LENGTH = 1;
  static readonly MAX_NO_LENGTH = 5;
  static readonly NO_LENGTH_EXACTLY = 4;
  static readonly FORMAT_NO_REGEX = '^[A-Za-z0-9]+$';
  static readonly FORMAT_NO_MERGE = '^[z0-9]+$';
  static readonly MAX_SUFFIX_LENGTH = 3;
  static readonly FORMAT_SUFFIX_REGEX = '^[A-Za-z0-9]+$';
  static readonly FORMAT_ROUTE_NAME_REGEX = '^[A-Za-z0-9 ]+$';
  static readonly FORMAT_BUS_STOP_NAME_REGEX = '^[A-Za-z0-9 ]+$';
  static readonly FORMAT_USER_ID_REGEX = '^[a-zA-Z0-9]{3,10}$';
  static readonly FORMAT_PASSWORD_REGEX = '^[a-zA-Z0-9]{8,16}$';

  static readonly MIN_NAME_LENGTH = 1;
  static readonly MAX_NAME_LENGTH = 64;

  static readonly KEY_LAST_TIMETABLE_ITEM_EDIT = 'last-timetable-item-edit';

  static readonly KEY_SIMPLE_SYNC_SETTING = 'simple-sync-setting';
  static readonly KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE = 'change-date-line-timetable';
  static readonly KEY_AREA_SWITCHING_TIMING = 'switch-timing-area';
  static readonly KEY_SETTING_MULTIPLE_TIMETABLES = 'is_multiple_timetables';
  static readonly KEY_UPPER_LIMIT = 'upper-limit';
  static readonly KEY_UPDATE_TIMING_TIMETABLE = 'update-timing-timetable';
  static readonly KEY_UPDATE_TIMING_SCHEDULE_MERGE = 'update-timing-schedule-merge';
  static readonly NO_TEMPLATE = 0;
  static readonly TEMPLATE_NO_AREA = 1;

  static readonly KEY_TIMETABLE_CHANGE_DATE_LINE_SCHEDULE = 'change-date-line-schedule';

  /**
   * constant format date
   */
  static readonly FORMAT_DATE_EXPORT = 'YYYYMMDDHHmmss';

  /**
   * constant max size 1GB
   */
  static readonly MAXIMUM_FILE_SIZE = 1073741824;

  // regex for time format
  static readonly FORMAT_TIME_COMMON_REGEX =
    '^(([0-{0}]?[0-9]:[0-5][0-9])|([{1}][0-{2}]:[0-5][0-9])|({3}:([0-{4}][0-9]|{5}[0-{6}])))(|:[0-5][0-9])$';
  static readonly FORMAT_TIME_MINUTE1_REGEX = '^(([0-{0}]?[0-9]:[0-5][0-9])|([{1}][0-{2}]:[0-5][0-9])|({3}:([0-{4}][0-9]|{5}[0-{6}])))$';
  static readonly FORMAT_TIME_MINUTE2_REGEX = '^(([0-{0}]?[0-9][0-5][0-9])|([{1}][0-{2}][0-5][0-9])|({3}([0-{4}][0-9]|{5}[0-{6}])))$';
  static readonly FORMAT_TIME_SECOND_REGEX =
    '^(([0-{0}]?[0-9]:[0-5][0-9])|([{1}][0-{2}]:[0-5][0-9])|({3}:([0-{4}][0-9]|{5}[0-{6}]))):([0-5][0-9])$';
  static readonly FORMAT_TIME_SECOND_REGEX_0 =
    '^(([0-{0}]?[0-9]:[0-5][0-9])|([{1}][0-{2}]:[0-5][0-9])|({3}:([0-{4}][0-9]|{5}[0-{6}]))):([0-5][0-9])$';
  static readonly FORMAT_TIME_MINUTE1_REGEX_0 = '^(([0-{0}]?[0-9]:[0-5][0-9])|([{1}][0-{2}]:[0-5][0-9])|({3}:([0-{4}][0-9]|{5}[0-{6}])))$';

  // number of elements of time format (HH:MM or HH:MM:SS). As length format when split(':').
  static readonly NUMBER_ELEMENTS_OF_MINUTE_FORMAT = 2;
  static readonly NUMBER_ELEMENTS_OF_SECOND_FORMAT = 3;

  static readonly TIME_ITEM = 'TIME';

  static readonly MAX_NUMBER_ITEM_HEADER = 100;

  static readonly HOURS = 0;
  static readonly MINUTES = 1;
  static readonly SECONDS = 2;

  static readonly SECOND_PER_HOUR = 3600;
  static readonly SECOND_PER_MINUTE = 60;

  // error index word editor
  static readonly ERROR_LIMIT_RECORD = 'Error limit record';
  static readonly ERROR_EXISTS_NAME = 'Error exists';
  static readonly ERROR_EXISTS_NAME_USER = 'already exists';
  static readonly ERROR_EXISTS_NAME_TIMETABLE = 'Error exists name';
  static readonly ERROR_EXISTS_SUFFIX_NO = 'Error exists suffix and no';
  static readonly ERROR_RECORD_NOT_EXISTS = 'Error record not exists';
  static readonly ERROR_INDEX_WORD_NOT_EXISTS = 'Error index word not exists';

  // error content when invalid multiple time formats value excel
  static readonly ERROR_MULTIPLE_TIME_FORMATS_VALUE_EXCEL = 'Multiple time formats are mixed';
  static readonly ERROR_INVALID_DATA_TIMETABLE_EXCEL = 'The value of the timetable is not valid';
  static readonly DUPLICATE_NO = 'duplicate no';
  static readonly HEADERS_NOT_MAP = 'Headers not map';
  // error content when invalid no data timetable  when import excel
  static readonly ERROR_NO_DATA_TIMETABLE_IN_EXCEL = 'No timetable value in excel';
  static readonly ERROR_NO_TEMPLATE = 'No template';
  static readonly ERROR_NO_AREA_TIMETABLE_OF_TEMPLATE = 'No area timetable';
  static readonly ERROR_HEADER_IS_EMPTY = 'Header is empty';
  static readonly MAX_RECORDS_TIMETABLE = 500;
  static readonly MAX_LENGTH_FILE_NAME = 64;
  static readonly IS_RECORDS_TIMETABLE_MAX = 'isRecordsTimetableMax';

  static readonly DEFAULT_LANGUAGE = 'jp';
  static readonly MAX_YEAR = 2;

  static readonly COMMON_INFORMATION = 'common';
  static readonly LAYOUT = 'layout';
  static readonly STOP_DURATION_DEFAULT = 0;
  static readonly SCROLL_SPEED_DEFAULT = 50;

  static readonly SOME_ERROR = 'some error';
  static readonly SUCCESS = 'success';

  static readonly ERROR_404_PATH = 'error/404';
  static readonly EN_LANGUAGE = 'en';
  static readonly JP_LANGUAGE = 'jp';
  static readonly SUSPENSION_JP = '運休';
  static readonly SUSPENSION_EN = 'suspension';
  static readonly CHARS_TO_ENCODE = /[&<>'"()¥$]/g;
  static readonly ENCODE_TO = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#39;',
    '"': '&quot;',
    '(': '&#40;',
    ')': '&#41;',
    '¥': '&yen;',
    $: '&#36;'
  };
  static readonly NETWORK_ERROR_CODE = 0;

  static readonly TIME_DATE_LINE_DEFAULT = '04:00';
  static readonly TIME_SWITCH_AREA_DEFAULT = 0;
  static readonly UPDATE_TIMING_DEFAULT = 0;
  static readonly IS_MULTIPLE_TIMETABLES_DEFAULT = false;
  static readonly SECONDS_OF_ONE_DAY = 86400;
  static readonly FORMAT_TIME_TO_MINUTES = 'HH:mm';
  static readonly FORMAT_TIME_TO_SECONDS = 'HH:mm:ss';
  static readonly SECONDS_STRING = 'seconds';
  static readonly TIME_PICKER_CLASS = 'dp-time-select-controls';
  static readonly MERIDIEM_TIME_PICKER_CLASS = 'dp-time-select-control-meridiem';
  static readonly JA = 'ja';
  static readonly EN_GB = 'en-gb';
  static readonly SIMPLE_CONTENT_DAY_ELEMENT = 'simpleContentDay';
  static readonly FORMAT_TIME_IN_DASHBOARD = 'YYYY/MM/DD HH:mm:ss';

  static readonly BUTTON_ADD_IMPORT_TIMETABLE = 'add';

  static readonly IS_NOT_USER_ROOT = 'isNotUserRoot';
  static readonly DEFAULT_TEMPLATE_LED_WIDTH = 240;
  static readonly DEFAULT_TEMPLATE_LED_HEIGHT = 40;
  static readonly MIN_TEMPLATE_WIDTH_SIZE = 1;
  static readonly MAX_TEMPLATE_WIDTH_SIZE = 240;
  static readonly MIN_TEMPLATE_HEIGHT_SIZE = 1;
  static readonly MAX_TEMPLATE_HEIGHT_SIZE = 40;

  static readonly LOCALE_CODE = 'en-US';
  static readonly FORMAT_DATE_TIME = 'yyyyMMdd HH:mm';
  static readonly FORMAT_DATE_TIME1 = 'YYYY-MM-DD HH:mm:ss';
  static readonly DUPLICATE_TIMETABLE_ID = 'duplicate timetableId';
  // led layout editor
  static readonly TITLE_ADD_TEMPLATE_GROUP = 'Add Template Group';
  static readonly TITLE_EDIT_TEMPLATE_GROUP = 'Edit Template Group';
  static readonly TITLE_ADD_TEMPLATE = 'Add Template';
  static readonly TITLE_EDIT_TEMPLATE = 'Edit Template';
  static readonly IS_SELECTED_TEMPLATE_GROUP = 'isSelectedTemplateGroup';
  static readonly IS_SHOW_CANVAS_LED = 'isShowCanvasLed';

  static readonly SCALE = 10;
  static readonly MAX_AREAS_EDS = 5;
  static readonly CANVAS_LED_LAYOUT_ID = 'canvasLEDLayoutRealTime';
  static readonly CANVAS_LED_CONTAINER_ID = 'canvasLEDContainer';
  static readonly BORDER_WIDTH_AREA = 3;
  static readonly FONT_SIZE_PC_DEFAULT = 20;
  // Destination scale
  static readonly DESTINATION_SCALE = 2;
  static readonly FONT_COLOR_AMBER = '#f0a30aff';
  static readonly VALUE_BITMAP_256_COLOR = 8;
  static readonly VALUE_BITMAP_MONOCHROME = 1;
  // Destination Sign Editor
  static readonly IS_PLAY_PREVIEW = 'isPlayPreview';
  static readonly IS_EDIT_DATA = 'isEditData';

  static readonly SIZE_ELEMENT = 'size';
  static readonly HEIGHT_ELEMENT = 'height';
  static readonly WIDTH_ELEMENT = 'width';
  static readonly ERROR_ELEMENT = 'error';
  static readonly SIZE_1MB = 1048576;
  static readonly NAME_ELEMENT = 'name';
  static readonly ERROR_KEY_USER_NOT_EXISTS = 'userNotExists';
  static readonly IS_CHOSEN = 'isChosen';
  static readonly IS_SHOW_USER_LIST = 'isShowUserList';
  static readonly IS_USER_ROOT = 'isUserRoot';
  static readonly HAS_NOT_USER_EXISTS = 'hasNotUserExists';

  // Device Manager
  static readonly SIGNAGE_GROUP = 'Signage_Group';

  static readonly AREAS_INDEX_WORD_INDEX = 0;
  static readonly GROUP_IDS_INDEX = 1;

  // sort & filter
  static readonly SORT_COLUMN_INDEX = 0;
  static readonly FIRST_ELEMENT_INDEX = 0;
  static readonly CAN_SORT_FILTER = 'canSortFilter';
  static readonly IS_SHOW_MENU_SETTING = 'isShowMenuSetting';

  static readonly MEDIA_TYPE_NUMBER = 2;
  static readonly DISABLE_BUTTON_DASHBOARD = 'disableButtonDashboard';
  static readonly IS_DELIVERY_ELEMENT = 'isDelivering';
  static readonly JOB_ID_ELEMENT = 'jobId';

  static readonly CANVAS_DISPLAY_DUMMY_STRING = '¯_';
  static readonly CANVAS_DISPLAY_TOP_RATIO = 0.95;
  static readonly CANVAS_DISPLAY_MIDDLE_RATIO = 2.5;
  static readonly CANVAS_DISPLAY_BOTTOM_RATIO = 1.45;
  static readonly FONT_LOADED = 'font loaded';

  static readonly DELIVERY_SUCCESS = 'deliverySuccessfull';

  static readonly DELIVERY_GROUP_KEY = 'group_delivery';
  static readonly DELIVERY_SINGLE_KEY = 'single_delivery';
  static readonly CMP_DELIVERY_GROUP_KEY = 'CMP_group';
  static readonly CMP_DELIVERY_SINGLE_KEY = 'CMP_single';

  // dashboard
  static readonly INVALID_DATE = 'invalid-date';

  static readonly CANVAS_LAYOUT_REALTIME = 'canvasLayoutRealTime';
  static readonly IS_NOT_DRAW_AREA = 'isNotDrawArea';
  static readonly IS_PLAY_MEDIA = 'isPlayMedia';
  static readonly FONT_COLOR = 'fontColor';
  static readonly BACKGROUND_COLOR = 'backgroundColor';
  static readonly IS_CHANGE_FONT_COLOR = 'isChangeFontColor';
  static readonly IS_CHANGE_BACKGROUND_COLOR = 'isChangeBackgroundColor';

  static readonly ERROR_LIMIT_SIZE = 'Error limit size';
  static readonly ERROR_LIMIT_HEIGHT = 'Error limit height';
  static readonly ERROR_LIMIT_WIDTH = 'Error limit width';
  static readonly ERROR_FONTS = 'Font is not Embedded';
  static readonly NAME_ATTRIBUTE = 'name';
  static readonly FILE_MEDIA_OBJECT = 1;
  static readonly URL_ATTRIBUTE = 'url';
  static readonly TYPE_ATTRIBUTE = 'type';
  static readonly IS_SIMPLE_SYNC_SETTING = 'isSimpleSyncSetting';
  static readonly IS_CHECKED_EXPIRATION = 'isCheckedExpiration';
  static readonly IS_CHECKED_PLAYBACK_TIME = 'isCheckedPlaybackTime';
  static readonly IS_MULTIPLE_TIMETABLES = 'isMultipleTimetables';
  static readonly FORMAT_DATE_DROP_MEDIA = 'YYYYMMDDHHmmss';
  static readonly GET_MEDIA_SUCCESS = 'success';
  static readonly TYPE_DYNAMIC_TEXT = 'text';
  static readonly TYPE_DYNAMIC_IMAGE = 'image';
  static readonly ACCESS_DENIED_ERROR = 'access_denied';
  static readonly CURRENT_DEFAULT = 20;
  static readonly ITEM_DEFAULT = 10;
  static readonly MAX_CURRENT_TIMETABLE = 100;
  static readonly MIN_CURRENT_TIMETABLE = 10;
  static readonly MAX_COLUMN_TIMETABLE = 100;
  static readonly MIN_COLUMN_TIMETABLE = 10;
  static readonly NUMBER_CURRENT_MAX = 20;

  static readonly REGEX_FORMAT_HEADERS_EN = '^ITEM_[0-9]{1,3}$';
  static readonly REGEX_FORMAT_HEADERS_JP = '^項目[0-9]{1,3}$';

  static readonly IS_TAB_DAILY = 'isTabDaily';
  static readonly IS_TAB_MONTHLY = 'isTabMonthly';
  static readonly IS_TAB_TIMETABLE = 'isTabTimetable';
  static readonly IS_TAB_CALENDAR = 'isTabCalendar';
  static readonly IS_TAB_CONFIRMATION = 'isTabConfirmation';
  static readonly IS_EDIT_ROW = 'isEditRow';

  /**
   * Schedule Merge
   */
  static readonly DEFAULT_DISPLAY_TEMPLATE = 6;
  static readonly LAST_NAME_DAILY_SCHEDULE = '日別運行予定表';
  static readonly LAST_NAME_MONTHLY_SCHEDULE = '月別運行予定表';
  static readonly COMPLETE_STATUS = 'completed';
  static readonly NETWORK_STATUS = 'network';
  static readonly ERROR_TITLE = 'error';
  static readonly COMMON_ERROR = 'common-error';
  static readonly IS_NETWORK_OK = 'isNetWorkOK';

  static readonly FORMAT_HOURS = 'HH:mm:ss';
  static readonly ENTER_KEY_CODE = 13;
  static readonly MAX_LENGTH_VALUE = 256;

  static readonly DEFAULT_TIMEOUT = 2;
  static readonly NUMBERS_OF_API_CALLS = 10;
  static readonly NUMBER_MILLISECONDS_CALL_API = 1000;
  static readonly DYNAMIC_MESSAGE = 'dynamicMessage';
  static readonly EMERGENCY_MESSAGE = 'emergencyMessage';
  static readonly IMAGE_ATTRIBUTE = 'image';
  static readonly MESSAGE_ATTRIBUTE = 'message';
  static readonly URL_MEDIA_DROP_PC = 'urlMediaDropPC';
  static readonly IMAGE_NAME_DROP_MEDIA = 'imageDynamic';
}

export enum PUBLISH_TYPE {
  BID = 'bid',
  DSC = 'dsc',
  BSD = 'sd',
  GID = 'gid',
  EDS = 'eds'
}

export enum FONT_TYPE {
  TTF = 'ttf',
  TTC = 'ttc'
}

export enum FIELD_COMPONENT {
  UserManagerComponent,
  ProjectManagerComponent,
  DeviceManagerComponent,
  MediaManagerComponent,
  IndexWordEditorComponent,
  MasterListEditorComponent,
  RouteListEditorComponent,
  TimetableEditorComponent,
  LcdLayoutEditorComponent,
  LedLayoutEditorComponent,
  DestinationSignEditorComponent,
  SignageDisplayEditorComponent,
  BusInformationDisplayEditorComponent,
  StationDisplayEditorComponent,
  DigitalSignageContentEditorComponent,
  ExternalContentManagerComponent,
  TimetableOperationManagerComponent,
  SimpleSignageEditorComponent,
  DeliveryManagerComponent,
  DashboardComponent,
  ScheduleMergeComponent,
  ScheduleOperationManagerComponent,
  ScheduleRegistrationComponent,
  LoginModalComponent
}

export enum MODULE_NAME {
  'User Manager',
  'Project Manager',
  'Device Manager',
  'Media Manager',
  'Index Word Editor',
  'Master List Editor',
  'Route List Editor',
  'Timetable Editor',
  'LCD Layout Editor',
  'LED Layout Editor',
  'Destination Sign Editor',
  'Signage Display Editor',
  'On-bus Display Editor',
  'Station Display Editor',
  'Digital Signage Channel Editor',
  'External Content Manager',
  'Timetable Operation Manager',
  'Simple Signage Editor',
  'Delivery Manager',
  'Dashboard',
  'Schedule Merge',
  'Schedule Operation Manager',
  'Schedule Registration',
  'Login',
  'Please, choose module !'
}

export const MODULE_NAME_NEED_PROJECT = [
  'Route List Editor',
  'Signage Display Editor',
  'On-bus Display Editor',
  'Station Display Editor',
  'Digital Signage Channel Editor'
];

export const menuComponents = [
  MenuUserComponent,
  MenuProjectComponent,
  MenuDeviceComponent,
  MenuMediaComponent,
  MenuIndexWordComponent,
  MenuMasterListComponent,
  MenuRouteListComponent,
  MenuTimetableEditorComponent,
  MenuLcdComponent,
  MenuLedLayoutEditorComponent,
  MenuDestinationSignComponent,
  MenuSignageDisplayEditorComponent,
  MenuBusInformationComponent,
  MenuStationDisplayEditorComponent,
  MenuDigitalSignageComponent,
  MenuExternalContentManagerComponent,
  MenuTimetableOperationManagerComponent,
  MenuSimpleSignageEditorComponent,
  MenuDeliveryComponent,
  MenuDashboardComponent,
  MenuScheduleMergeComponent,
  MenuScheduleOperationManagerComponent,
  MenuScheduleRegistrationComponent,
  EdsEditorComponent
];

export const COLOR_TITLE = [
  '#EFAE83',
  '#85ADDD',
  '#92A6C3',
  '#CCD475',
  '#B8AD7E',
  '#EB99BB',
  '#A0A3B3',
  '#8AADB8',
  '#67AAC7',
  '#5CA8B1',
  '#E3BA72',
  '#5CA798',
  '#DE9BCD',
  '#A091D5',
  '#8AB1A1',
  '#DBC76E',
  '#B09BBF',
  '#97B582',
  '#F49C86',
  '#909FDD',
  '#8AADB8',
  '#B09BBF',
  '#8AADB8',
  '#000'
];

export const ALL_SOFTWARES = [
  'User Manager',
  'Project Manager',
  'Device Manager',
  'Media Manager',
  'Index Word Editor',
  'Master List Editor',
  'Route List Editor',
  'Timetable Editor',
  'LCD Layout Editor',
  'LED Layout Editor',
  'Destination Sign Editor',
  'Signage Display Editor',
  'On-bus Display Editor',
  'Station Display Editor',
  'Digital Signage Channel Editor',
  'External Content Manager',
  'Timetable Operation Manager',
  'Simple Signage Editor',
  'Delivery Manager',
  'Dashboard',
  'Schedule Merge',
  'Schedule Operation Manager',
  'Schedule Registration'
];

export enum TYPE_LAYOUT {
  TYPE,
  TYPE1,
  TYPE2,
  TYPE3,
  TYPE4,
  TYPE5,
  TYPE6
}

export enum NUMBER_LAYOUT {
  LAYOUT_1,
  LAYOUT_2,
  LAYOUT_3
}

export enum EditTemplateToolsEnum {
  SELECT_AREA,
  ADD_FIX_TEXT,
  ADD_LINK_TEXT,
  ADD_FIX_PICTURE,
  ADD_LINK_PICTURE,
  ZOOM,
  PAN,
  ALIGN
}

export enum LinkDataPictureEnum {
  INDEX_WORD,
  POSITION_DRIVEN,
  EXTERNAL_SOURCE,
  SIGNAGE_CHANNEL,
  REFER_TO_SELECTED_NO,
  EMERGENCY_MESSAGE,
  FREE_PICTURE,
  EXTERNAL_CONTENT,
  DYNAMIC_MESSAGE
}

export enum LinkDataTextEnum {
  CLOCK,
  ROUTE_NO,
  ROUTE_NAME,
  INDEX_WORD,
  BUS_STOP_NAME,
  POSITION_DRIVEN,
  EXTERNAL_SOURCE,
  BUS_STOP_NO,
  ARRIVAL_TIME,
  NEXT_ARRIVAL_TIME,
  EMERGENCY_MESSAGE,
  FREE_TEXT,
  DEVICE_STATUS,
  EXTERNAL_CONTENT,
  TIMETABLE,
  OPERATION_INFO,
  DYNAMIC_MESSAGE
}

export enum AutoLogoutTime {
  NEVER,
  FIVE_MINUTE,
  TEN_MINUTE,
  THIRSTY_MINUTE,
  ONE_HOUR
}

export enum ObjectFitEnum {
  FILL,
  COVER,
  CONTAIN
}

export enum ReferencePositionRowEnum {
  CURRENT_POSITION,
  CURRENT_1,
  CURRENT_2,
  CURRENT_3,
  CURRENT_4,
  CURRENT_5,
  CURRENT_6,
  CURRENT_7,
  CURRENT_8,
  CURRENT_9
}

export enum ReferencePositionColumnEnum {
  INDEX_WORD_1,
  INDEX_WORD_2,
  INDEX_WORD_3,
  INDEX_WORD_4,
  INDEX_WORD_5,
  INDEX_WORD_6,
  INDEX_WORD_7,
  INDEX_WORD_8,
  INDEX_WORD_9,
  INDEX_WORD_10
}

export enum ReferencePositionTimetableColumnEnum {
  TIME
}

export enum TimingOnEnum {
  LAST_3_STOP = -3,
  LAST_2_STOP = -2,
  LAST_1_STOP = -1,
  LAST_STOP = 0,
  STOP_REQUEST = 1,
  ARRIVAL = 2,
  DOOR_CLOSE = 3,
  FROM_THE_BEGINNING = 4
}

export enum TimingOffEnum {
  LAST_3_STOP = -3,
  LAST_2_STOP = -2,
  LAST_1_STOP = -1,
  LAST_STOP = 0,
  STOP_REQUEST = 1,
  ARRIVAL = 2,
  DOOR_CLOSE = 3,
  DURATION = 4,
  FILE_END = 5,
  TO_THE_END = 6
}

export enum TextStyleEnum {
  BOLD,
  ITALIC
}

export enum AlignmentEnum {
  LEFT,
  CENTER,
  RIGHT,
  TOP,
  MIDDLE,
  BOTTOM
}

export enum OrientationEnum {
  HORIZONTAL,
  VERTICAL,
  SIDEWAYS
}

export enum ScrollStatusEnum {
  OFF,
  ON,
  AUTO
}

export enum ScrollDirectionsEnum {
  LEFT,
  RIGHT,
  UP,
  DOWN,
  SEMI_UP,
  SEMI_DOWN
}

export enum ScrollDirectionsCodeEnum {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down'
}

export enum TypeMediaFileEnum {
  ALL = 'all',
  BMP = 'bmp',
  PNG = 'png',
  JPG = 'jpg',
  GIF = 'gif',
  WAV = 'wav',
  MP3 = 'mp3',
  MP4 = 'mp4',
  TXT = 'txt',
  SEQ = 'seq',
  PDF = 'pdf'
}

export const TypeMediasNotSupported = ['svg', 'svg+xml'];

export const GIFFileExtensions = ['gif', 'image/gif'];

export enum TypeMediaInDashBoard {
  VIDEO = 'video',
  IMAGE = 'image'
}

export enum OutputOptionEnum {
  ONE_STOP,
  SECOND,
  CONTINUOUS,
  ONCE,
  REPEAT
}

export enum TypeResizeAreaEnum {
  CHANGE_X_Y_W_H = 1,
  CHANGE_X_W = 2,
  CHANGE_X_W_H = 3,
  CHANGE_H = 4,
  CHANGE_W_H = 5,
  CHANGE_W = 6,
  CHANGE_Y_W_H = 7,
  CHANGE_Y_H = 8
}

export enum PreviewControlEnum {
  RE_PREVIEW = 0,
  PREV = 1,
  NEXT = 2,
  DOOR_CLOSE = 3,
  DOOR_OPEN = 4,
  STOP_REQUEST = 5,
  PLAY = 6,
  PAUSE = 7
}

export enum TimeDivision {
  LEVEL_1 = 0,
  LEVEL_2 = 288,
  LEVEL_3 = 576,
  LEVEL_4 = 864,
  LEVEL_5 = 1152,
  LEVEL_6 = 1440
}

export enum TemplateModeEnum {
  ALL = -1,
  ON_BUS_DISPLAY = 0,
  STATION_DISPLAY = 1,
  SIGNAGE_DISPLAY = 2,
  EXTERNAL_CONTENT = 3,
  TIMETABLE = 4
}

export enum TemplateTypeEnum {
  MAIN = 0,
  SUB_PAGE_1 = 1,
  SUB_PAGE_2 = 2,
  SUB_PAGE_3 = 3,
  SUB_PAGE_4 = 4,
  EMERGENCY = 5
}

export enum DestinationEnum {
  MAIN = 0,
  SUB_PAGE_1 = 1,
  SUB_PAGE_2 = 2,
  SUB_PAGE_3 = 3,
  SUB_PAGE_4 = 4,
  EMERGENCY = 5
}

export enum OnClickEventTypeEnum {
  GO_TO = 0,
  SHOW_CONNECTED_MEDIA = 1
}

export enum ArrivalTimeFormatEnum {
  HH_MM = 'hhmm',
  N_MIN = 'nmin'
}

export enum DisplaysEnum {
  NONE = -1,
  DISPLAY_1 = 0,
  DISPLAY_2 = 1,
  DISPLAY_3 = 2,
  DISPLAY_4 = 3,
  DISPLAY_5 = 4,
  DISPLAY_6 = 5
}

export enum ScreenNameEnum {
  ON_BUS_DISPLAY = 'On-bus Display',
  STATION_DISPLAY = 'Station Display',
  SIGNAGE_DISPLAY = 'Signage Display',
  DIGITAL_SIGNAGE_CHANNEL_DISPLAY = 'Digital Signage Channel',
  DESTINATION_SIGN_DISPLAY = 'Destination Sign',
  TIME_TABLE = 'Timetable',
  SIMPLE_SIGNAGE = 'Simple Signage',
  LCD = 'LCD',
  LED = 'LED',
  SCHEDULE_REGISTRATION = 'Schedule Registration'
}

export enum ScreenFunctionId {
  MASTER_LIST = 1,
  ROUTE_LIST = 2,
  TIME_TABLE = 3,
  SCHEDULE_REGISTRATION = 4
}

export enum DeliveryJobStatusEnum {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled'
}

export enum DeviceStatusEnum {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
  WAITING = 'Waiting',
  FAILED = 'Failed',
  NO_RESPONSE = 'No Response',
  CANCELLED = 'Cancelled'
}

export enum PayloadDeviceStatusEnum {
  COMPLETED = 'SUCCEEDED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'QUEUED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELED'
}

export enum DeviceDetailStatusEnum {
  CREATE_DATA = 'create-data',
  PREPARING_DATA = 'preparing-data',
  DELIVERY_COMPLETING = 'delivery-completing',
  DELIVERY_COMPLETED = 'delivery-completed',
  CREATE_DATA_ERROR = 'create-data-error',
  PREPARING_DATA_ERROR = 'preparing-data-error',
  DELIVERY_DATA_ERROR = 'delivery-data-error'
}

export enum WeatherContentSourceEnum {
  WEATHER_LOCATION = 0,
  DATE_TIME_MM_DD = 1,
  DATE_TIME_HH = 2,
  TEMP = 3,
  WEATHER_NO = 4
}

export enum NewsContentSourceEnum {
  TITLE = 0,
  CONTENT_TYPE = 1
}

export enum DeviceTypeEnum {
  DESTINATION_SIGN = 'Destination',
  SIGNAGE_DISPLAY = 'Signage',
  ON_BUS_DISPLAY = 'On-bus',
  STATION_DISPLAY = 'Station'
}

export const TEMPLATE_TYPE = ['Main', 'Sub1', 'Sub2', 'Sub3', 'Sub4'];

export enum ErrorEnum {
  EMPTY,
  MAX_LENGTH,
  MIN_LENGTH,
  MIN,
  MAX,
  NON_SELECT,
  PREVIEW_IS_PLAYING,
  PROPERTY_IS_EDITING,
  PROPERTY_HAS_NO_TEMPLATE
}

export enum MediaTypeEnum {
  IMAGE = 0,
  VIDEO = 1,
  AUDIO = 2,
  SEQUENCE = 3,
  TEXT = 4
}

export enum RepeatModeEnum {
  EVERY_DAY = 'everyDay',
  EVERY_WEEK = 'everyWeek'
}

export enum UserRoleEnum {
  ADMINISTRATOR = 1,
  MANAGER = 2,
  USER = 3
}

export enum ScreenTypeEnum {
  BID = 'bid',
  SD = 'sd',
  GID = 'gid',
  DSC = 'dsc',
  EDS = 'eds'
}

/**
 * enum PERIOD
 */
export enum PERIOD {
  DAILY,
  WEEKLY,
  MONTHLY
}

/**
 * enum DASHBOARD_TAB_NAME
 */
export enum DASHBOARD_TAB_NAME {
  DEVICE_MONITOR,
  CONTENT_REPORT,
  PROPERTIES,
  LOG,
  SCREEN_CAPTURE
}

export enum PreviewToolEnum {
  PAN,
  ZOOM
}

export enum SettingType {
  SIGNAGE,
  TIMETABLE,
  SCHEDULE_REGISTRATION,
  SCHEDULE_MERGE
}

export enum ScreenCanvasIdEnum {
  TIMETABLE_EDITOR = 'timetableEditor',
  TIMETABLE_OPERATION_MANAGER = 'timetableOperation',
  DESTINATION_SIGN_EDITOR = 'destinationSign'
}

export enum DisplayCanvasIdEnum {
  DISPLAY_1 = 'CanvasDisplay1',
  DISPLAY_2 = 'CanvasDisplay2',
  DISPLAY_3 = 'CanvasDisplay3',
  DISPLAY_4 = 'CanvasDisplay4',
  DISPLAY_5 = 'CanvasDisplay5',
  DISPLAY_6 = 'CanvasDisplay6'
}

export enum TemplateTypeFreeAreaEnum {
  MAIN = 'main',
  SUB1 = 'sub1',
  SUB2 = 'sub2',
  SUB3 = 'sub3',
  SUB4 = 'sub4',
  EMERGENCY = 'emergency'
}

export enum TypeDataSettingContentDayEnum {
  DSC = 'playlist',
  TT = 'timetable',
  SS = 'playlistSimple',
  SR = 'scheduleRegistration'
}

export enum SortTypeEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum DisplayTimingTypeEnum {
  FINISH_TIMETABLE = 0
}

export enum ErrorCodeSimpleMedia {
  ERROR_MAX_LENGTH_MEDIA_CODE = -1,
  ERROR_MAX_BIT_RATE_VIDEO_CODE = -2,
  ERROR_MAX_VIDEO_FRAME_RATE_CODE = -3,
  ERROR_MAX_WIDTH_VIDEO_CODE = -4,
  ERROR_MAX_HEIGHT_VIDEO_CODE = -5,
  ERROR_OTHER_CODE = -10
}

export enum FormatDateEnum {
  FORMAT_EN = 'MMM.dd',
  FORMAT_JP = 'MM/dd'
}

export enum LEDTemplateModeEnum {
  ALL = -1,
  DESTINATION_SIGN = 0,
  ON_BUS_DISPLAY = 1,
  SIGNAGE_LED = 2
}

export enum LEDTemplateTypeEnum {
  DISPLAY_1 = 0,
  DISPLAY_2 = 1,
  DISPLAY_3 = 2,
  DISPLAY_4 = 3,
  DISPLAY_5 = 4,
  DISPLAY_6 = 5
}

export enum DisplayModelEnum {
  FULL_COLOR = 0,
  AMBER = 1,
  WHITE = 2
}

export enum ReferencePositionColumnEDSEnum {
  ROUTE_NO,
  ROUTE_NAME,
  ADJUSTED_ROUTE_NO,
  ADJUSTED_ROUTE_NAME,
  BUS_STOP_NAME,
  ADJUSTED_BUS_STOP_NAME
}

export enum DelimitersEnum {
  SEMICOLON,
  COMMA,
  SPACE,
  OTHER
}

export enum LinkDataPictureLEDEnum {
  INDEX_WORD,
  EXTERNAL_CONTENT
}

export enum LinkDataTextLEDEnum {
  ROUTE_NO,
  ROUTE_NAME,
  ADJUSTED_ROUTE_NO,
  ADJUSTED_ROUTE_NAME,
  BUS_STOP_NAME,
  ADJUSTED_BUS_STOP_NAME,
  INDEX_WORD,
  EXTERNAL_CONTENT
}

export enum SpeedEnum {
  LOW,
  MID,
  HIGH,
  VERY_HIGH
}

export const OUTLINE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export enum ErrorCodeDestination {
  ROUTE_MAX = 'route-max',
  ROUTE_NO_FORMAT_INVALID = 'route-no-format-invalid',
  DUPLICATE_ROUTE = 'duplicate-route',
  SUFFIX_FORMAT_INVALID = 'suffix-format-invalid',
  ROUTE_NAME_FORMAT_INVALID = 'route-name-format-invalid',
  BUS_STOP_NO_FORMAT_INVALID = 'bus-stop-no-format-invalid',
  BUS_STOP_NAME_FORMAT_INVALID = 'bus-stop-name-format-invalid',
  HEADER_FORMAT_INVALID = 'header-format-invalid',
  START_CODE_FORMAT_INVALID = 'start-code-format-invalid',
  NO_START_CODE = 'no-start-code',
  ADJUSTED_BUS_STOP_NAME_FORMAT_INVALID = 'adjusted-bus-stop-name-format-invalid',
  LABEL_FORMAT_INVALID = 'label-format-invalid',
  ADJUSTED_ROUTE_NO_FORMAT_INVALID = 'adjusted-route-no-format-invalid',
  ADJUSTED_ROUTE_NAME_FORMAT_INVALID = 'adjusted-route-name-format-invalid',
  TEMPLATE_FORMAT_INVALID = 'template-format-invalid'
}

export enum DisplayIndexEnum {
  DISPLAY_1 = 1,
  DISPLAY_2 = 2,
  DISPLAY_3 = 3,
  DISPLAY_4 = 4,
  DISPLAY_5 = 5,
  DISPLAY_6 = 6
}

export enum FontTypeEnum {
  PC_FONT = 'PC',
  BITMAP_FONT = 'BMP'
}

export enum DelimitersDataEnum {
  SEMICOLON = ';',
  COMMA = ',',
  SPACE = ' '
}

export enum PlayLogTypeEnum {
  START_CHANNEL = 'startChannel',
  START_SEQUENCE = 'startSequence',
  MEDIA = 'media'
}

/**
 * Role Id Enum
 */
export enum RoleIdEnum {
  ADMINISTRATOR = 1,
  MANAGER = 2,
  USER = 3
}

/**
 * Role Name Enum
 */
export enum RoleNameEnum {
  ADMINISTRATOR = 'Administrator',
  MANAGER = 'Manager',
  USER = 'User'
}

/**
 * Active column header
 */
export enum ActiveColumnHeader {
  WAITING,
  IN_PROGRESS,
  COMPLETED,
  CANCEL,
  FAILED,
  TOTAL
}

export enum FolderNameDropPDFEnum {
  INDEX_WORD_EDITOR = 'Index Word Editor',
  LCD_LAYOUT_EDITOR = 'LCD Layout Editor',
  FREE_AREA = 'freeArea',
  EMERGENCY = 'emergency',
  DYNAMIC_MESSAGE = 'Dynamic Message'
}

export enum ReferenceColumnEnum {
  ITEM_1
}

/**
 * Status number object
 */
export class StatusNumberObject {
  waitingNumber: number = 0;
  inprogressNumber: number = 0;
  completedNumber: number = 0;
  cancelNumber: number = 0;
  failedNumber: number = 0;
  total: number;

  constructor() {}
}

/**
 * API Enum
 */
export enum APIEnum {
  OFF_EMERGENCY = 0,
  DYNAMIC_MESSAGE = 1,
  ON_EMERGENCY = 2
}
