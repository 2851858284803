import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CSRFService } from 'app/core/auth/csrf.service';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
import { ExecutingService } from 'app/service/executing.service';
import { AppState } from 'app/store/app.state';
import { defer, Observable } from 'rxjs';
import { finalize, retryWhen, tap } from 'rxjs/operators';
import { AutoLogoutTime, Constant } from './constants';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {
  /**
   * ERROR_DETAIL_TO_STOP_RETRY
   */
  private ERROR_DETAIL_TO_STOP_RETRY = [
    Constant.ERROR_INVALID_DATA_TIMETABLE_EXCEL,
    Constant.ERROR_MULTIPLE_TIME_FORMATS_VALUE_EXCEL,
    Constant.ERROR_NO_DATA_TIMETABLE_IN_EXCEL,
    Constant.ERROR_NO_TEMPLATE,
    Constant.ERROR_NO_AREA_TIMETABLE_OF_TEMPLATE,
    Constant.ERROR_HEADER_IS_EMPTY,
    Constant.ERROR_LIMIT_RECORD,
    Constant.ERROR_EXISTS_NAME,
    Constant.ERROR_RECORD_NOT_EXISTS,
    Constant.ERROR_EXISTS_NAME_TIMETABLE,
    Constant.ERROR_EXISTS_NAME_USER,
    Constant.ERROR_EXISTS_SUFFIX_NO,
    Constant.ERROR_INDEX_WORD_NOT_EXISTS,
    Constant.ERROR_LIMIT_SIZE,
    Constant.ERROR_LIMIT_HEIGHT,
    Constant.ERROR_LIMIT_WIDTH,
    Constant.DUPLICATE_TIMETABLE_ID,
    Constant.DUPLICATE_NO,
    Constant.HEADERS_NOT_MAP,
    Constant.ERROR_FONTS
  ];

  /**
   * common object
   */
  commonObject: Common;
  constructor(
    public csrfService: CSRFService,
    private executingService: ExecutingService,
    public readonly store: Store<AppState>,
    public commonService: CommonService
  ) {
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let csrfToken = this.csrfService.getCSRF();
    let timeout = -1;
    let setting = this.commonObject.setting;
    if (setting) {
      switch (setting.autoLogoutTime) {
        case AutoLogoutTime.FIVE_MINUTE:
          timeout = 5 * 60;
          break;
        case AutoLogoutTime.TEN_MINUTE:
          timeout = 10 * 60;
          break;
        case AutoLogoutTime.THIRSTY_MINUTE:
          timeout = 30 * 60;
          break;
        case AutoLogoutTime.ONE_HOUR:
          timeout = 60 * 60;
          break;
        case AutoLogoutTime.NEVER:
        default:
          break;
      }
    }
    request = !request.url.includes('auth/login')
      ? request.clone({
          setHeaders: {
            'X-XSRF-TOKEN': csrfToken,
            [Constant.TENANT_HEADER]: this.commonObject.tenantName,
            sessionTimeout: timeout + '',
            [Constant.USER_ID]: this.commonObject?.userIdString ?? ''
          }
        })
      : request.clone({ setHeaders: { 'X-XSRF-TOKEN': csrfToken, sessionTimeout: timeout + '' } });
    const handleHttp = next.handle(request).pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(errorStatus => {
            this.executingService.executed();
            if (!request.url.includes('auth/login') && errorStatus.status != Constant.NETWORK_ERROR_CODE) {
              const functionName = request.url.substring(request.url.lastIndexOf('/') + 1);
              this.commonService.writeErrorLog(JSON.stringify(errorStatus.error), this.commonObject.moduleName, functionName).toPromise();
            }
            if (
              (this.ERROR_DETAIL_TO_STOP_RETRY.includes(errorStatus.error?.detail) && errorStatus.status == 500) ||
              (Constant.SKIP_RETRY_URLS.some(url => request.url.includes(url)) && errorStatus.status == 500) ||
              (!errorStatus.status?.toString().startsWith('5') && errorStatus.status != 408)
            ) {
              throw errorStatus;
            }
            console.log('Retrying...' + errorStatus.status);
          })
          // delay(1000),
          // take(1)
        )
      ),
      finalize(() => {
        if (!Constant.SKIP_WAITING_URLS.some(url => request.url.includes(url))) {
          this.executingService.executed();
        }
      })
    );

    const httpEvent = defer(() => {
      if (!Constant.SKIP_WAITING_URLS.some(url => request.url.includes(url))) {
        this.executingService.executing();
      }
      return handleHttp;
    });
    return httpEvent;
  }
}
